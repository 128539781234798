<template>
  <div :class="!cliente ? 'ev2-box' : ''" style="min-height: 400px">
    <div v-if="!cliente" class="header">
      <div class="hcol1">
        <div class="title">Tickets registro.br <small class="text-blue-grey">({{table.serverPagination.rowsNumber}})</small></div>
        <div class="subtitle">Tickets de aprovação para domínios .leilao.br</div>
      </div>
      <div class="hcol2">
        <i class="fal fa-refresh cursor-pointer m-t-sm m-r" @click="load" v-if="!table.loading" />
        <sl-loading v-else class="size-18 m-t-sm m-r" content="" />
        <erp-input v-model="table.filters.search" @keydown.enter="load" class="sl-input input-sm" placeholder="Buscar" :before="[{icon: 'search', handler () {}}]" />
        <!--<erp-select v-model="buscaTipo" :options="buscaTipoOpts" class="sl-input input-sm m-l-sm" />-->
      </div>
    </div>
    <div v-else>
      <i class="fal fa-refresh cursor-pointer m-t-sm m-r" @click="load" v-if="!table.loading" />
    </div>
    <div class="body">
      <u-table
          ref="table"
          color="primary"
          :data="table.serverData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="ev2-dash-table with-bottom"
          :rows-per-page-options="[20, 50, 100]"
          :hide-no-results-label="true"
      >
        <u-tr slot="header" slot-scope="props">
          <u-th v-for="col in props.cols" :key="col.name" :props="props">
            <template>
              {{ col.label }}
            </template>
            <div v-if="col.name === 'options'" class="text-center">
            </div>
          </u-th>
        </u-tr>

        <u-tr slot="body" slot-scope="props" :props="props" class="cursor-pointer" @click.native="$router.push({name: 'fenaju.tarefa', params: {id: props.row.id}})">
          <u-td class="td-limit_ l-130_" key="cliente" :props="props">
            <div class="flex items-center">
              <div class="app-person-avatar m-r-sm flex justify-center full-width">
                <img v-if="props.row.cliente && props.row.cliente.photo" :src="props.row.cliente.photo">
                <div v-else-if="props.row.cliente" class="img-fake" style="background-color: #399B9F">{{ firstLettersName(props.row.cliente.name) }}</div>
                <div v-else class="img-fake" style="background-color: #399B9F">--</div>
              </div>
              <div class="m-l-xs flex items-center column">
                <div>
                  <div v-if="props.row.cliente"> <!-- style="max-width: 80px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden" -->
                    {{props.row.cliente.name}}
                    <u-tooltip :offset="[10,10]">
                      {{props.row.cliente.name}}
                    </u-tooltip>
                  </div>
                  <div v-else>-</div>
                </div>
              </div>
            </div>
          </u-td>
          <u-td style="max-width: 40px; min-width: 40px; width: 40px" key="id" :props="props">
            {{ String(props.row.id).padStart(4, '0') }}
          </u-td>
          <u-td class="td-limit l-130" key="description" :props="props" style="padding-right: 20px">
            {{props.row.description}}
          </u-td>
          <u-td class="" key="status" :props="props">
            <div :class="'task-status-code-' + props.row.status.code">{{props.row.status.name}}</div>
          </u-td>
          <u-td class="text-right" key="options" :props="props">
            <div class="cursor-pointer">
              <div class="text-right no-select">Ações <i class="fa fa-chevron-down text-grey-5 font-10" /></div>
              <u-popover class="window-context-menu menu-profile min" anchor="bottom right" self="top right">
                <ul>
                  <menu-options-item close label="Abrir" @click="$emit('abrir')" />
                </ul>
              </u-popover>
            </div>
          </u-td>
        </u-tr>
      </u-table>
    </div>
    <div class="footer">
      <div class="text-center">
        <u-btn @click="criarTarefa" class="f-btn bg-black" no-caps label="Nova OS" />
      </div>
    </div>
  </div>
</template>

<script>
import ErpInput from "@/plugins/uloc-erp/components/form/input"
import ErpSelect from "@/plugins/uloc-erp/components/form/select"
import tableColumnsCache from "@/utils/tableColumnsCache"
import {UTable, UTr, UTh, UTd, date, UPopover} from "uloc-vue"
import {datePtToEn} from "@/utils/date"
import MenuOptionsItem from "components/layout/context-menu/context-window-options-item.vue"
import {list} from "@/domain/tarefas/services"
import filters from "@/domain/tarefas/helpers/filtersConsole"
import SlLoading from "components/layout/components/Loading.vue"
import criarTarefaWindow from "components/fenaju/components/suporte/tarefas/window/criarTarefa";

const listName = 'table.console.os'
const listStorage = tableColumnsCache(listName, [
  {label: 'Leiloeiro', name: 'cliente', active: true, sortable: true, ordering: 1},
  {label: 'ID Ticket', name: 'id', active: true, sortable: true, ordering: 1},
  {label: 'Descrição', name: 'description', active: true, sortable: true, ordering: 1},
  {label: 'Situação', name: 'status', active: true, sortable: true, ordering: 1},
], 2)
export default {
  name: "ClientesOs",
  props: {
    cliente: {
      required: false
    }
  },
  components: {
    SlLoading,
    MenuOptionsItem,
    //ErpSelect,
    ErpInput,
    UTable, UTr, UTh, UTd, UPopover
  },
  data () {
    const filtros = JSON.parse(JSON.stringify(filters))
    filtros.tipo = ['os']
    filtros.internalStatus = ['opened']
    return {
      busca: null,
      buscaTipo: 'pendentes',
      buscaTipoOpts: [
        {label: 'Com pendência', value: 'pendentes'},
      ],
      listStorage: listStorage,
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 0, // specifying this determines pagination is server-side
          rowsPerPage: 20
        },
        columns: listStorage.map(o => {
          if (o.name === 'cliente' && this.cliente && this.cliente.id) {
            o.active = false
          }
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        })/*.concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ])*/,
        filter: '',
        filters: filtros,
        selected: [],
        loading: false
      }
    }
  },
  mounted() {
    this.load()
  },
  computed: {
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    }
  },
  methods: {
    request({pagination, filter}) {

      // this.table.serverPagination.rowsNumber = 0

      let data1, data2
      let extraFilters = []

      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10 || (!this.table.filters.data2 || this.table.filters.data2.length < 10)) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }

      this.table.filters.tipoData && extraFilters.push(`&typeDate=${this.table.filters.tipoData}`)

      this.table.filters.search && extraFilters.push(`&search=${String(this.table.filters.search).replace('#', '_hash-')}`)

      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      !this.fila && this.table.filters.internalStatus && extraFilters.push(`&internalStatus=${this.table.filters.internalStatus.join(',')}`)
      this.table.filters.tipo && extraFilters.push(`&type=${this.table.filters.tipo}`)
      this.table.filters.active && extraFilters.push(`&active=${this.table.filters.active}`)
      this.table.filters.cliente && extraFilters.push(`&cliente=${this.table.filters.cliente.id}`)
      this.table.filters.assigned && extraFilters.push(`&assigned=${this.table.filters.assigned.id}`)
      this.table.filters.author && extraFilters.push(`&author=${this.table.filters.author.id}`)
      this.table.filters.lider && extraFilters.push(`&owner=${this.table.filters.lider.id}`)
      this.table.filters.permission && extraFilters.push(`&permission=${this.table.filters.permission}`)
      this.table.filters.public && extraFilters.push(`&public=${this.table.filters.public}`)
      this.table.filters.project && extraFilters.push(`&project=${this.table.filters.project?.id}`)
      this.table.filters.priority && extraFilters.push(`&priority=${this.table.filters.priority.id}`)
      this.table.filters.type && extraFilters.push(`&type=${this.table.filters.type.id}`)
      this.table.filters.fila && extraFilters.push(`&queue=${this.table.filters.fila.replace('id:', '')}`)
      // Filtra somente tarefas vencidas
      this.table.filters.dueFilter && extraFilters.push(`&dueFilter=${this.table.filters.dueFilter}`)
      this.table.filters.status && extraFilters.push(`&status=${this.table.filters.status.id}`)
      this.table.filters.typeFilter && extraFilters.push(`&typeFilter=${this.table.filters.typeFilter}`)

      if ((!this.table.filters.internalStatus || !this.table.filters.internalStatus.length) && !this.table.filters.status) {
        extraFilters.push(`&internalStatus=opened`)
      }

      this.cliente && this.cliente.solicitante && extraFilters.push(`&cliente=${this.cliente.solicitante.id}`)

      /*if (Array.isArray(this.tags) && this.tags.length > 0) {
        extraFilters.push(`&tags=${this.tags.join(',')}`)
      }*/

      if (this.extraFilters && Array.isArray(this.extraFilters)) {
        this.extraFilters.map(ef => {
          extraFilters.push('&' + ef)
        })
      }

      this.table.loading = true
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'createdAt'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : false}${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros, true)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    load () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    criarTarefaWindow,
    criarTarefa () {
      this.criarTarefaWindow(null, this.cliente ? this.cliente.solicitante : null)
    }
  }
}
</script>
